const colorConstant = {
  amexWhite: "#FFFFFF",
  amexBlue: " #3C659EFC",
  fontColorLiteBlue: "#C1D1E7",
  fontColorBlack: "#18181B",
  iconRed: "#F94545",
  alertRed: "#D32828",
  amexTextColor: "#656565",
  amexCancelBtnColor: "#686868",
  amexCancelBorderColor: "#E1E1E1",
  amexErrorColor: "#F14141",
};

export default colorConstant;
