import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const useLoginHelper = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const loginInitialValue = {
    email: '',
    password: '',
  };

  const forgetPasswordInitialValues = {
    email: '',
  };

  const resetPasswordInitialValue = {
    newPass: '',
    confirmPass: '',
    passCode: '',
  };

  const handleClearPassword = (e: React.ChangeEvent<HTMLInputElement>, setValues: any) => {
    if (e.target.value !== null) {
      setValues((prevValues: any) => ({
        ...prevValues,
        // we use the name to tell Formik which key of `values` to update
        password: '',
      }));
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>, setValues: any) => {
    if (e.target.value !== null) {
      setValues((prevValues: any) => ({
        ...prevValues,
        // we use the name to tell Formik which key of `values` to update
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return {
    dispatch,
    location,
    loginInitialValue,
    forgetPasswordInitialValues,
    resetPasswordInitialValue,
    navigate,
    isLinkSent,
    setIsLinkSent,
    showPassword,
    handleClearPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    handleChangePassword,
    showConfirmPassword,
    handleClickShowConfirmPassword,
  };
};

export default useLoginHelper;
