import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

export class UserManageState {
  type: number = 1;
  listingType: number = 2;
  listingData = [];
  userListCount: number = 0;
  userListPage: number = 1;
  userListLimit: number = 10;
  userListSearch: string = '';
  userListPageCount: number = 1;

  // audit log
  auditListData = [];
  auditListCount: number = 0;
  auditListPage: number = 1;
  auditListLimit: number = 10;
  auditListPageCount: number = 1;

  selectedData: Array<string> = [];
  userName: string = '';
  userEmail: string = '';
  contactNumber: string = '';
  passCode: string = '';
  userId: string = '';
  userStatus: number = 1;
  bankId: string = '';
  bankName: string = '';
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  filterStatus = 0;
  isFilter: boolean = false;
  currentSortKey = 'createdAt';
  isCurrentSortAscending = false;

  //  notification inifite scroll and search states
  typeNotif: number = 1;
  listingTypeNotif: number = 2;
  listingDataNotif = [];
  userListCountNotif: number = 0;
  userListPageNotif: number = 1;
  userListLimitNotif: number = 10;
  userListSearchNotif: string = '';
  userListPageCountNotif: number = 1;
  dial_code: string = '';
}

export const UserManageReducer = (
  state: UserManageState = new UserManageState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.USER_MANAGE) {
    return { ...state, ...action.payload };
  }
  return state;
};
