import { List, ListItemButton, ListItemIcon, Box, ListItemText } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';
import Helpers from '../../helpers';
import ACCOUNT_INFO from '../../constants/accountInfo';
import { styles } from './style';
import routes from '../../constants/routes';
import LocalImages from '../../constants/localImages';
// import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

function MainMenu() {
  let location = useLocation();

  /**
   * Button used in side menu listing
   * @param menu
   * @returns button of list
   */
  const listButtonForMenu = (menu: any) => (
    <ListItemButton
      selected={
        !menu.subMenu ? menu.route === location.pathname || !!menu.isNestedRouteSelected : false
      }
      sx={styles.menuButton}
    >
      <ListItemIcon>
        <Box component="img" src={menu.icon} sx={styles.menuLogo} alt="icon" />
      </ListItemIcon>
      <ListItemText sx={styles.menuTextStyle} primary={menu.title} />
    </ListItemButton>
  );

  /**
   * to check nested routes
   * @param selectedRoutes
   * @returns pathname
   */
  const isNestedRouteSelected = (selectedRoutes: string[]) => {
    return selectedRoutes.includes(location.pathname);
  };

  const menuItems = [
    {
      title: 'Dashboard',
      icon: LocalImages.MENU_HOME,
      route: routes.dashboard,
      isShow: true,
    },
    {
      title: 'User Management',
      icon: LocalImages.MENU_USER,
      route: routes.MANAGER_MANAGE,
      isShow: Helpers.getAccountType() !== ACCOUNT_INFO.ROLES.tpBankOperator,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.MANAGER_MANAGE,
        routes.OPERATOR_MANAGE,
        routes.managerDetails,
        routes.operatorDetails,
      ]),
    },
    {
      title: 'Case Inbox',
      icon: LocalImages.MENU_INBOX,
      route: routes.CASE_INBOX_RECEIVED,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.CASE_INBOX_SENT,
        routes.CASE_INBOX_RECEIVED,
        routes.CASE_INBOX,
        routes.CASE_INBOX_CLOSED,
      ]),
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankManager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankOperator,
    },
    {
      title: 'Audit Log',
      icon: LocalImages.MENU_AUDIT,
      route: routes.AUDIT_LOG,
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankManager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankSuperAdmin,
    },
    {
      title: 'Manage Notifications',
      icon: LocalImages.NOTIFICATION_WHITE,
      route: routes.MANAGE_NOTIFICATIONS,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.MANAGE_NOTIFICATIONS,
        routes.ADD_NOTIFICATION,
      ]),
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankManager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankSuperAdmin,
    },

    {
      title: 'Transaction Details',
      icon: LocalImages.MENU_TRANSACTION,
      // icon: <ReceiptLongOutlinedIcon color="secondary" />,
      isComponentIcon: true,
      isNestedRouteSelected: isNestedRouteSelected([
        routes.TRANSACTION_DETAILS,
        routes.AUTH_TRANSACTION_DETAILS,
      ]),
      route: routes.AUTH_TRANSACTION_DETAILS,
      isShow:
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankManager ||
        Helpers.getAccountType() === ACCOUNT_INFO.ROLES.tpBankOperator,
    },
  ];

  return (
    <Box sx={styles.mainContainer}>
      <List sx={styles.mainMenuContainer}>
        {menuItems.map((menu) => {
          return (
            <div style={!menu.isShow ? { display: 'none' } : {}}>
              <Link to={menu.route}>{listButtonForMenu(menu)}</Link>
            </div>
          );
        })}
      </List>
    </Box>
  );
}

export default MainMenu;
