import ACTION_NAMES from '../../constants/actionNames';

export class UserNotificationState {
  notificationData: any = [];
  page = 1;
  limit = 10;
  count = 0;
  unReadCount = 0;
}

export const UserNotificationReducer = (
  state: UserNotificationState = new UserNotificationState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.USER_NOTIFICATIONS) {
    return { ...state, ...action.payload };
  }
  return state;
};
