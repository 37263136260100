import ActionNames from '../../constants/actionNames';

export class UserProfileData {
  userName: string = '';
  userEmail: string = '';
  contactNumber: string = '';
  profilePicture: string = '';
  userId: string = '';
  passCode: string = '';
  accountType: number = 1;
}

export const UserProfileReducer = (
  state: UserProfileData = new UserProfileData(),
  action: any = {}
) => {
  if (action.type === ActionNames.USER_PROFILE) {
    return { ...state, ...action.payload };
  }
  return state;
};

export default UserProfileReducer;
