import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

// class CaseResult {}

export class DashboarState {
  type: number = 1;
  listingType: number = 2;
  listingData = [];
  userListCount: number = 0;
  userListPage: number = 1;
  userListLimit: number = 10;
  userListSearch: string = '';
  userListPageCount: number = 1;

  activeSecondPresentment = '0';
  caseClosed = '0';
  caseFulfillmentFifteen = '0';
  caseFulfillmentFive = '0';
  caseFulfillmentTen = '0';
  caseRegisterInDay = '0';
  secondPresentmentFive = '0';
  secondPresentmentTen = '0';

  // caseResult: CaseResult | null = null;

  // dashboard items AESA
  activeManger = '0';
  activeOperator = '0';
  blockManger = '0';
  blockOperator = '0';

  bankId: string = '';
  bankName: string = '';
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  filterStatus = null;
  isFilter: boolean = false;

  activeChargeBack = '0';
  activeFinalChargeBack = '0';
  activeRetrievalRequest = '0';
  pendingChargeBackFifteen = '0';
  pendingChargeBackFive = '0';
  pendingChargeBackTen = '0';
  pendingFinalChargeBackFifteen = '0';
  pendingFinalChargeBackFive = '0';
  pendingFinalChargeBackTen = '0';
  pendingRetrievalFifteen = '0';
  pendingRetrievalFive = '0';
  pendingRetrievalTen = '0';
  //new keys
  activeFulfillment = '0';
  secondPresentmentFifteen = '0';
}

export const DashboardReducer = (state: DashboarState = new DashboarState(), action: any = {}) => {
  if (action.type === ACTION_NAMES.DASHBOARD) {
    return { ...state, ...action.payload };
  }
  return state;
};
