const ENDPOINTS = {
  login: 'tpbank/onboarding/login',
  users: 'tpbank/users?page=2',
  forgetPassword: 'tpbank/onboarding/forgetPassword',
  verifyToken: 'tpbank/onboarding/verifyToken',
  resetPassword: 'tpbank/onboarding/resetPassword/',
  verifyEmail: 'tpbank/onboarding/verifyToken',
  createPassword: 'tpbank/onboarding/createPassword/',
  addUser: 'tpbank/addUser',
  logout: 'tpbank/onboarding/logout',
  recoveryAccount: 'tpbank/recoveryAccount',
  downloadUserList: 'tpbank/downloadUserList',
  profile: 'tpbank/profile',
  listing: 'tpbank/user/listing',
  auditListing: 'tpbank/audit/listing',
  blockUnblockUser: 'tpbank/block',
  changePassword: 'tpbank/changePassword',
  file: 'tpbank/file',
  tpBank: 'tpbank',
  downloadTPBankList: 'tpbank/downloadTPBankList',
  createCase: 'tpbank/case',
  createCaseDocUpload: 'tpbank/case/upload',
  caseInboxListing: 'tpbank/case/inbox/listing',
  caseDetails: 'tpbank/case/caseDetails',
  caseDownload: 'tpbank/case/download',
  caseAction: 'tpbank/case/action',
  caseTransactionListing: 'tpbank/case/transaction/listing',
  createCaseValidationMsg: 'tpbank/case/validationMsg',
  auditLogListing: 'tpbank/audit/log',
  dashboard: 'tpbank/dashboard',
  downloadDashboard: 'tpbank/DashboardDownload',
  notification: 'notification',
  notificationCount: 'notification/count',
  userNotification: 'notification/my',
  deleteNotificationAll: 'notification/all',
  notificationList: 'notification/listing',
  deleteNotification: 'notification/',
  downloadNotification: 'notification/download',
  notificationResend: 'notification/resend',
  scanCaseDoc: 'tpbank/case/scanFile',
  searchTransactionDetails: 'tpbank/transaction/listing',
  searchAuthTransactionDetails: 'tpbank/authTransaction/listing',
};

export default ENDPOINTS;
