import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

export class NotificationState {
  notificationlistingData = [];
  notificationLimit: number = 10;
  notificationStatus: number = 1;
  notificationPageCount: number = 1;
  notificationPage: number = 1;
  notificationSearch: string = '';
  notificationCount: number = 0;
  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  isFilter: boolean = false;
  filterStatus: number = 0;
  sentToData = [];
  currentSortKey = 'createdAt';
  isCurrentSortAscending = true;
}

export const NotificationReducer = (
  state: NotificationState = new NotificationState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.MANAGE_NOTIFICATIONS) {
    return { ...state, ...action.payload };
  }
  return state;
};
