export const COLORS = {
  black: '#000000',
  white: '#ffffff',
  grey: 'rgba(84, 84, 84, 0.8)',
  inputBorder: '#83898B',
  inputText: '#242424',
  btnColor: '#272727',
  primary: '#3F679C',
  shadow: 'rgba(120, 120, 120, 0.2)',
  headingBlue: '#1B203B',
  lightBorder: '#F4F6FA',
  bgColor: '#F4F7FC',
  greyText: 'rgba(0, 0, 0, 0.6)',
  greenBg: '#E7F5EE',
  green: '#19A85B',
  tableBlue: '#656F91',
  greyOpacityText: 'rgba(32, 38, 45, 0.6)',
  red: '#F74343',
  formBorder: '#DFE3EA',
  formBg: '#FDFDFD',
  greyMainText: '#525863',
  borderGrey: '#ECECF5',
  tableText: '#343B44',
  orangeBg: '#FDF2E1',
  orange: '#EB971A',
  redText: '#DD3D27',
  redBg: '#FCEFED',
  checkBorder: '#C5C7CE',
  inputColor: '#233755',
  blueDrop: '#3F6BA6',
  textGrey: '#666666',
};
