import React from 'react';
import styles from './alertStyles';

interface Props {
  icon?: string | React.ReactElement;
  message?: string;
  style?: Object;
  close: Function;
}

export default function Alert({ icon, message, style, close }: Props) {
  const classes = styles();

  // const [seconds, setSeconds] = React.useState(10);
  const ref = React.useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   if (seconds > 0) {
  //     setTimeout(() => setSeconds(seconds - 1), 1000);
  //   } else {
  //     close();
  //     if (ref.current) {
  //       ref.current.click();
  //     }
  //   }
  // }, [seconds, close]);

  return (
    <div className={classes.container} style={style} ref={ref}>
      <div className={classes.leftContainer}>
        <>
          {icon} <span className={classes.message}>{message}</span>
        </>
      </div>
    </div>
  );
}
