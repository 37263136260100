import React from 'react';

import {
  // toast,
  ToastContainer,
} from 'react-toastify';
import './App.css';
import Router from './router/index';

// toast.configure({
//   autoClose: 3000,
//   draggable: false,
//   newestOnTop: true,
//   position: "top-right",
//   closeOnClick: true,
// });

function App() {
  return (
    <>
      <Router />
      <ToastContainer closeButton={false} />
    </>
  );
}

export default App;
