import React from 'react';
import MainMenu from '../menu';
import MainHeader from '../MainHeader';
import { Box } from '@mui/system';
import { Backdrop } from '@mui/material';
import Spinner from '../spinner';
import { useSelector } from 'react-redux';
import { ReducersState } from '../../redux/rootReducer';
import { getStorageItem, getUserIdToken, setStorageItem } from '../../helpers';
import { Navigate, useLocation } from 'react-router-dom';

export default function PrivateContainer({ children, headerText }: any) {
  const { isLoading } = useSelector((state: ReducersState) => state.GlobalReducer);

  // Logging out the user from AMEX if the user logs out from any tab
  const location = useLocation();

  window.addEventListener('storage', (e) => {
    if (e.key === 'accessToken') {
      window.location.reload();
    }
  });

  /* Page styling start */
  const styles = {
    universalComp_mainContainer: {
      width: '100%',
      height: '100vh',
      overflow: 'scroll',
      background: '#f5f7fb',
      '& .MuiBackdrop-root': {
        zIndex: '1400 !important',
      },
    },

    universalComp_menuContainer: {
      display: 'flex',
    },
    rightContainer: {
      width: '76% !important',
      padding: '0 2%',
      backgroundColor: 'rgb(245 247 253)',
      height: '100vh',
    },

    universalComp_contentContainer: {},
  };
  /* Page styling end */

  if (getUserIdToken()) {
    return (
      <Box sx={styles.universalComp_mainContainer}>
        <Backdrop open={isLoading || false}>
          <Spinner />
        </Backdrop>
        <MainHeader headerText={headerText} />
        <Box sx={styles.universalComp_menuContainer}>
          <MainMenu />
          <Box sx={styles.rightContainer}>{children}</Box>
        </Box>
      </Box>
    );
  }
  const pathname = window.location.pathname + window.location.search;
  const token = getStorageItem('accessToken');

  if (!token && pathname !== '/') {
    setStorageItem('redirectTo', pathname);
  }
  return <Navigate to="/" state={{ from: location }} />;
}
