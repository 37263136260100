import ACTION_NAMES from '../../constants/actionNames';

export class AuditLogState {
  auditLogListing = [];
  auditLogListCount: number = 0;
  auditLogListPage: number = 1;
  auditLogListLimit: number = 10;
  auditLogListSearch: string = '';
  auditLogListPageCount: number = 1;
}

export const AuditLogReducer = (state: AuditLogState = new AuditLogState(), action: any = {}) => {
  if (action.type === ACTION_NAMES.AUDIT_LOG) {
    return { ...state, ...action.payload };
  }
  return state;
};
