import { applyMiddleware, legacy_createStore, compose } from "redux";
import Thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import reducers from "./rootReducer";
import { apiMiddleware } from "../middlewares/index";

const logger = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

const store: ReturnType<typeof legacy_createStore> = legacy_createStore(
  reducers,
  compose(applyMiddleware(Thunk, logger), applyMiddleware(apiMiddleware))
);
export default store;
