import { CaseInboxReducer, CaseInboxState } from './../pages/caseInbox/reducer';
import { combineReducers } from 'redux';
import { UserManageState, UserManageReducer } from '../pages/userManage/reducer';
import { GlobalReducer, GlobalState } from './globalReducer';
import { UserProfileReducer, UserProfileData } from '../pages/userProfile/reducer';
import { AuditLogReducer, AuditLogState } from './../pages/auditLog/reducer';
import { LocalMachineReducer, LocalMachineData } from '../pages/login/reducer';
import { DashboarState, DashboardReducer } from '../pages/Dashboard/reducer';
import { NotificationState, NotificationReducer } from '../pages/manageNotifications/reducer';
import { UserNotificationReducer, UserNotificationState } from '../components/MainHeader/reducer';
import {
  TransactionDetailsReducer,
  TransactionDetailsState,
} from '../pages/transactionDetails/reducer';

export class ReducersState {
  GlobalReducer: GlobalState = new GlobalState();
  UserProfileReducer: UserProfileData = new UserProfileData();
  LocalMachineReducer: LocalMachineData = new LocalMachineData();
  UserManageReducer: UserManageState = new UserManageState();
  CaseInboxReducer: CaseInboxState = new CaseInboxState();
  AuditLogReducer: AuditLogState = new AuditLogState();
  DashboardReducer: DashboarState = new DashboarState();
  NotificationReducer: NotificationState = new NotificationState();
  UserNotificationReducer: UserNotificationState = new UserNotificationState();
  TransactionDetailsReducer: TransactionDetailsState = new TransactionDetailsState();
}

/**
 * combine all reducer into single root reducer
 */
const rootReducer = combineReducers({
  GlobalReducer,
  UserProfileReducer,
  LocalMachineReducer,
  UserManageReducer,
  CaseInboxReducer,
  AuditLogReducer,
  DashboardReducer,
  NotificationReducer,
  UserNotificationReducer,
  TransactionDetailsReducer,
});

export default rootReducer;
