import * as Yup from 'yup';

const passwordError =
  'Password must be between 8-40 characters, it must have 1 Uppercase(A-Z), 1 lowercase (a-z), 1 number (0-9), and a special character except space';
const passRegrex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?!.* ).{8,40}$/;

const amountRegex = /^\d{0,1000}(\.\d{1,2})?$/;

/**
 * Sign in Form Validation Schema
 */
const LoginSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Please enter the required field')
      .email('Please enter a valid email address'),
    password: Yup.string()
      .required('Please enter the required field')
      .matches(passRegrex, passwordError),
  });

/**
 * Forget Password Form Validation Schema
 */
const ForgetPasswordSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
  });

/**
 * Reset Password Form Validation Schema
 */
const ResetPassword = () => {
  return Yup.object().shape({
    newPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError),
    confirmPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError)
      .when('newPass', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('newPass')], 'Password doesn’t match'),
      }),
  });
};

/**
 * Create Password Form Validation Schema
 */
const CreatePassword = () => {
  return Yup.object().shape({
    newPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError),
    confirmPass: Yup.string()
      .trim()
      .required('Please fill in the required field')
      .matches(passRegrex, passwordError)
      .when('newPass', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('newPass')], 'Password doesn’t match'),
      }),
    passCode: Yup.string()
      .required('Please fill in the required field')
      .matches(/^\d{6}$/, 'Passcode should have 6 digits'),
  });
};

/**
 * Add a Manager Form Validation Schema
 */
const AddManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
    passCode: Yup.string()
      .required('Please fill in the required field')
      .matches(/^\d{6}$/, 'Passcode should have 6 digits'),
  });

/**
 * Edit a Manager/Operator Form Validation Schema
 */
const EditManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(50, 'Only 50 Characters are allowed for this field')
      .min(1, 'There should be atleast 1 Character for this field')
      .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field ')
      .required('Please fill in the required field'),
    email: Yup.string()
      .max(50, 'Only 50 Characters are allowed for this field')
      .trim()
      .required('Please fill in the required field')
      .email('Please enter a valid email address'),
    pNo: Yup.string()
      .trim()
      .matches(/^\d+$/, 'Phone Number should contain only numbers')
      .max(9, 'Phone Number should have 9 digits')
      .min(9, 'Phone Number should have 9 digits')
      .nullable(),
  });

/**
 * Change Password Validation Schema
 */
const ChangePasswordSchema = () => {
  return Yup.object().shape({
    oldPassword: Yup.string().trim().required('Old Password is required'),
    newPassword: Yup.string()
      .trim()
      .required('New password is required')
      .matches(passRegrex, passwordError)
      .test('passwords-match', 'New password same as old password', function passwordMatch(value) {
        return this.parent.oldPassword !== value;
      }),
    confirmPassword: Yup.string()
      .trim()
      .required('Confirm password is required')
      .matches(passRegrex, passwordError)
      .test('passwords-match', 'New password same as old password', function passwordMatch(value) {
        return this.parent.oldPassword !== value;
      })
      .when('newPassword', {
        is: (val: any) => val && val.length > 0,
        then: Yup.string().oneOf([Yup.ref('newPassword')], 'Both password doesn’t match'),
      }),
  });
};

/**
 * Add a Super Admin Form Validation Schema
 */
const AddCaseSchema = () =>
  Yup.object().shape({
    transDate: Yup.string().required('Please fill in the required field'),
    cardNumber: Yup.string()
      .matches(/^[0-9]{15}$/, 'Only 15 digit numbers are allowed for this field')
      .required('Please fill in the required field')
      .nullable(),
    seNum: Yup.string()
      .matches(/^[0-9]{10}$/, 'Only 10 digit numbers are allowed for this field')
      .required('Please fill in the required field')
      .nullable(),
    transTime: Yup.string()
      .matches(
        /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/,
        'Field can have only time in hh:mm:ss format'
      )
      .required('Please fill in the required field'),
    seName: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'SE Name can be alpha numeric')
      .required('Please fill in the required field'),
    transAmount: Yup.string()
      .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Please enter valid amount')
      .required('Please fill in the required field'),
    merchantCode: Yup.string()
      .matches(/^[0-9]{4}[aA-zZ-]+$/, 'Please enter valid merchant code')
      .required('Please fill in the required field'),
    presentmentAmount: Yup.string()
      .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Please enter valid amount')
      .required('Please fill in the required field'),
    arnNo: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'Please enter valid ARN number')
      .required('Please fill in the required field')
      .max(23, 'ARN number cannot exceed 23 characters')
      .min(10, 'ARN number must have atleast 10 characters'),
    aprCode: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'Approval code can be alpha numeric')
      .max(6, 'ARN number cannot exceed 6 characters')
      .required('Please fill in the required field'),
    terminalNo: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, 'Enter valid terminal number')
      .max(8, 'Terminal number cannot exceed 8 characters')
      .required('Please fill in the required field'),
    transId: Yup.string().matches(
      /^[0-9]{10}$/,
      'Only 10 digit numbers are allowed for this field'
    ),
    transType: Yup.string().required('Please fill in the required field'),
    retrievalReason: Yup.string().when('tabValue', {
      is: 0,
      then: Yup.string().required('Please fill in the required field'),
    }),
    docType: Yup.string().when('tabValue', {
      is: 0,
      then: Yup.string().required('Please fill in the required field'),
    }),
    cbReason: Yup.string().when('tabValue', {
      is: 1,
      then: Yup.string().required('Please fill in the required field'),
    }),
    cbAmt: Yup.string().when('tabValue', {
      is: 1,
      then: Yup.string()
        .matches(amountRegex, 'Please enter valid amount upto 2 decimal places')
        .max(15, 'Amount must not exceed 15 digits')
        .required('Please fill in the required field'),
    }),
    finalCb: Yup.string().when('tabValue', {
      is: 2,
      then: Yup.string().required('Please fill in the required field'),
    }),
    finalCbAmt: Yup.string().when('tabValue', {
      is: 2,
      then: Yup.string()
        .matches(amountRegex, 'Please enter valid amount upto 2 decimal places')
        .max(15, 'Amount must not exceed 15 digits')
        .required('Please fill in the required field'),
    }),
  });

/**
 * Fulfillment  validation schema
 * @returns error text
 */
const FulFillmentSchema = () =>
  Yup.object().shape({
    docType: Yup.string(),
    fulfillmentReason: Yup.string().required('Please fill in the required field'),
  });

/**
 * Second Presentment validation schema
 * @returns error text
 */
const SecondPresentmentSchema = () =>
  Yup.object().shape({
    secondPresentmentReason: Yup.string().required('Please fill in the required field'),
    secondPresentmentAmount: Yup.string()
      .max(15, 'Amount must not exceed 15 digits')
      .required('Please fill in the required field')
      .matches(amountRegex, 'Please enter valid amount upto 2 decimal places'),
  });

/**
 * Accept retrieval request form validation schema
 * @returns error text
 */
const AcceptRetrievalRequestSchema = () =>
  Yup.object().shape({
    acceptRetrievalRequestReason: Yup.string().trim().required('Please fill in the required field'),
  });

/**
 * Add Notification form validation schema
 * @returns error text
 */
const AddNotificationSchema = () =>
  Yup.object().shape({
    selectAudience: Yup.string().trim().required('Please fill in the required field'),
    notificationTitle: Yup.string()
      .trim()
      .min(1, 'There should be atleast 1 Character for this field')
      .max(25, 'Field must not exceed 25 characters')
      .required('Please fill in the required field'),
    sendTo: Yup.array().min(1, 'Please select atleast one option'),
    discription: Yup.string()
      .trim()
      .min(1, 'There should be atleast 1 Character for this field')
      .max(250, 'Field must not exceed 250 characters')
      .required('Please fill in the required field'),
    sendToId: Yup.array(),
    isEmail: Yup.boolean(),
  });
const FormValidation = {
  LoginSchema,
  ForgetPasswordSchema,
  ResetPassword,
  CreatePassword,
  AddManagerSchema,
  ChangePasswordSchema,
  AddCaseSchema,
  SecondPresentmentSchema,
  FulFillmentSchema,
  AcceptRetrievalRequestSchema,
  AddNotificationSchema,
  EditManagerSchema,
};

export default FormValidation;
