import React from 'react';
import Modal from '@mui/material/Modal';

export default function SimpleModal({ isOpen, handleClose, body }: any) {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}
