// Package imports
import { ThemeProvider } from '@mui/material';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// utility imports
import lightTheme from '../components/theme';
import { getStorageItem } from '../helpers';
import routes from '../constants/routes';
import { API } from '../middlewares';
import ACCOUNT_INFO from '../constants/accountInfo';

// Public Route
import PublicRoute from './PublicRoute';

// Wrappers
import LoginWrapper from '../components/loginWrapper/index';
import PrivateContainer from '../components/PrivateContainer';
import IntermediateRoute from './IntermediateRoute';

// Routes
import Login from '../pages/login';
const ForgetPassword = lazy(() => import('../pages/forgetPassword'));
const ResetPassword = lazy(() => import('../pages/resetPassword'));
const CreatePassword = lazy(() => import('../pages/createPasswordForNewUser'));
const UserManage = lazy(() => import('../pages/userManage'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const UserProfile = lazy(() => import('../pages/userProfile'));
const UserDetail = lazy(() => import('../pages/userManage/userDetail'));
const AddUser = lazy(() => import('../pages/userManage/addUser'));
const CaseInbox = lazy(() => import('../pages/caseInbox'));
const CaseInboxDetail = lazy(() => import('../pages/caseInbox/caseInboxDetail'));
const AuditLog = lazy(() => import('../pages/auditLog'));
const NotFound = lazy(() => import('../components/pageNotFound'));
const ChangePassword = lazy(() => import('../pages/userProfile/changePassword'));
const ContactUs = lazy(() => import('../pages/contactUs'));
const ManageNotifications = lazy(() => import('../pages/manageNotifications'));
const AddNotifications = lazy(() => import('../pages/manageNotifications/addNotification'));
const TransactionDetails = lazy(() => import('../pages/transactionDetails'));

// list of routes
// Note: intermediate route behaves like public route but does not redirect to the dashboard
const routesList = [
  {
    path: routes.login,
    isPrivate: false,
    isIntermediate: false,
    component: <Login />,
  },
  {
    path: routes.forget_password,
    isPrivate: false,
    isIntermediate: true,
    component: <ForgetPassword />,
  },
  {
    path: routes.RESET_PASSWORD,
    isPrivate: false,
    isIntermediate: true,
    component: <ResetPassword />,
  },
  {
    path: routes.CREATE_PASSWORD,
    isPrivate: false,
    isIntermediate: true,
    component: <CreatePassword />,
  },
  {
    path: routes.dashboard,
    isPrivate: true,
    component: <Dashboard />,
    headerText: 'Dashboard',
  },
  {
    path: routes.userProfilePath,
    isPrivate: true,
    component: <UserProfile />,
    headerText: 'My Profile',
  },
  {
    path: routes.CHANGE_PASSWORD,
    isPrivate: true,
    component: <ChangePassword />,
    headerText: 'Reset Password',
  },
  {
    path: routes.MANAGER_MANAGE,
    isPrivate: true,
    component: <UserManage type={ACCOUNT_INFO.ROLES.tpBankManager} />,
    headerText: 'User Management',
  },
  {
    path: routes.OPERATOR_MANAGE,
    isPrivate: true,
    component: <UserManage type={ACCOUNT_INFO.ROLES.tpBankOperator} />,
    headerText: 'User Management',
  },
  {
    path: routes.managerDetails,
    isPrivate: true,
    component: <UserDetail type={ACCOUNT_INFO.ROLES.tpBankManager} />,
    headerText: 'User Management',
  },
  {
    path: routes.operatorDetails,
    isPrivate: true,
    component: <UserDetail type={ACCOUNT_INFO.ROLES.tpBankOperator} />,
    headerText: 'User Management',
  },
  {
    path: routes.ADD_MANAGER,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.tpBankManager} />,
    headerText: 'User Management',
  },
  {
    path: routes.ADD_OPERATOR,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.tpBankOperator} />,
    headerText: 'User Management',
  },
  {
    path: routes.EDIT_MANAGER,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.tpBankManager} />,
    headerText: 'User Management',
  },
  {
    path: routes.EDIT_OPERATOR,
    isPrivate: true,
    component: <AddUser type={ACCOUNT_INFO.ROLES.tpBankOperator} />,
    headerText: 'User Management',
  },
  {
    path: routes.CASE_INBOX_SENT,
    isPrivate: true,
    component: <CaseInbox />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_INBOX_RECEIVED,
    isPrivate: true,
    component: <CaseInbox />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_INBOX_CLOSED,
    isPrivate: true,
    component: <CaseInbox />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.CASE_INBOX,
    isPrivate: true,
    component: <CaseInboxDetail />,
    headerText: 'Case Inbox',
  },
  {
    path: routes.AUDIT_LOG,
    isPrivate: true,
    component: <AuditLog />,
    headerText: 'Audit Log',
  },
  {
    path: routes.CONTACT_US,
    isPrivate: true,
    component: <ContactUs />,
    headerText: 'Contact Us',
  },
  {
    path: routes.MANAGE_NOTIFICATIONS,
    isPrivate: true,
    component: <ManageNotifications />,
    headerText: 'Notification Management',
  },
  {
    path: routes.ADD_NOTIFICATION,
    isPrivate: true,
    component: <AddNotifications />,
    headerText: 'Add Notification',
  },
  {
    path: routes.AUTH_TRANSACTION_DETAILS,
    isPrivate: true,
    component: <TransactionDetails isAuthTransaction={true} />,
    headerText: 'Auth Transaction Details',
  },
  {
    path: routes.TRANSACTION_DETAILS,
    isPrivate: true,
    component: <TransactionDetails isAuthTransaction={false} />,
    headerText: 'Transaction Details',
  },
];

// 1: public, 2: intermediate route, 3: private route
const getMyRoute = (
  isPrivate: boolean,
  path: string,
  component: any,
  isIntermediate = false,
  headerText?: string
) => {
  if (isPrivate) {
    return (
      <Route
        path={path}
        element={<PrivateContainer headerText={headerText}>{component}</PrivateContainer>}
      />
    );
  } else if (!isPrivate && isIntermediate) {
    return (
      <Route
        path={path}
        element={<LoginWrapper childComp={<IntermediateRoute>{component}</IntermediateRoute>} />}
      />
    );
  } else {
    return (
      <Route
        path={path}
        element={<LoginWrapper childComp={<PublicRoute>{component}</PublicRoute>} />}
      />
    );
  }
};

/**
 * Root router components
 * @param props parameter for Router function
 */
function Router() {
  const accessToken: any = getStorageItem('accessToken');
  if (accessToken !== undefined && accessToken !== null) {
    API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <BrowserRouter
        basename={process.env.REACT_APP_SUB_DIRECTORY ? process.env.REACT_APP_SUB_DIRECTORY : '/'}
      >
        <Suspense
          fallback={
            <div>
              <span>Loading....</span>
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />

            {routesList.map(({ isPrivate, path, component, isIntermediate, headerText }) =>
              getMyRoute(isPrivate, path, component, isIntermediate, headerText)
            )}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Router;
