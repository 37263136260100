import showAlert from '../../components/alert';
import ACTION_NAMES from '../../constants/actionNames';
import endpoints from '../../constants/endpoints';
import routes from '../../constants/routes';
import { checkResponseStatus, getStorageItem, setStorageItem } from '../../helpers';
import { API, PUBLIC_API } from '../../middlewares';
import { updateGlobalLoader } from '../../redux/globalActions';

/**
 * Login api
 */
export const loginApi = (values: any, setSubmitting: any, navigate: any) => (dispatch: any) => {
  dispatch({
    type: ACTION_NAMES.IS_LOADING,
    payload: true,
  });

  const { email, password } = values;

  const dataToSend = {
    email,
    password,
  };

  setSubmitting(true);
  PUBLIC_API.post(endpoints.login, dataToSend)
    .then((response: any) => {
      let { data } = response;
      if (checkResponseStatus(response)) {
        const { isTempPassword, token } = data?.data;
        let redirectPath = getStorageItem('redirectTo');
        if (redirectPath && process.env.REACT_APP_SUB_DIRECTORY) {
          if (process.env.REACT_APP_SUB_DIRECTORY !== '/')
            redirectPath = redirectPath.replace(process.env.REACT_APP_SUB_DIRECTORY, '');
        }

        // Checking whether user is logging with temporary created password.
        // redirecting the user to create new password once the temporary password is verified
        if (isTempPassword) {
          showAlert(1, 'Create your password to login to the application');
          navigate(`/create-password?token=${token}&type=2`);
          return;
        }

        // login flow
        localStorage.setItem('accessToken', data.data.token);
        API.defaults.headers.common.Authorization = `Bearer ${data.data.token}`;
        dispatch({
          type: ACTION_NAMES.LOCAL_DATA_FETCH,
          payload: {
            accountType: data?.data?.accountType,
          },
        });
        setStorageItem('accountType', data?.data?.accountType);
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate(routes.dashboard);
        }
      } else {
        const errorMsg = response?.data?.error?.join(', ') || response?.data?.message;
        showAlert(2, errorMsg);

        dispatch(updateGlobalLoader(false));
      }

      setSubmitting(false);
      dispatch({
        type: ACTION_NAMES.IS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      setSubmitting(false);

      if (error?.response) {
        showAlert(2, `${error?.response?.data?.message}`);
      } else {
        showAlert(2, `Something went wrong..`);
      }
      dispatch({
        type: ACTION_NAMES.IS_LOADING,
        payload: false,
      });
    });
};

export const logoutApi = (navigate: any) => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  const dataToSend = {};
  API.post(endpoints.logout, dataToSend)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('redirectTo');

        delete API.defaults.headers.common['Authorization'];
        navigate('/');
      } else {
        const errorMsg = response?.data?.error?.join(', ') || response?.data?.message;
        showAlert(2, errorMsg);
        dispatch(updateGlobalLoader(false));
      }
      localStorage.removeItem('accessToken');
      localStorage.removeItem('redirectTo');
      delete API.defaults.headers.common['Authorization'];

      navigate('/');
      dispatch({
        type: ACTION_NAMES.IS_LOADING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: ACTION_NAMES.IS_LOADING,
        payload: false,
      });
      localStorage.removeItem('accessToken');
      delete API.defaults.headers.common['Authorization'];
      localStorage.removeItem('redirectTo');
      navigate('/');
    });
};

/**
 * Service to get user profile
 */
export const getProfile = () => (dispatch: any) => {
  dispatch(updateGlobalLoader(true));

  API.get(endpoints.profile)
    .then((response: any) => {
      if (checkResponseStatus(response)) {
        const { profileData } = response.data.data;
        dispatch(updateGlobalLoader(false));
        dispatch({
          type: ACTION_NAMES.USER_PROFILE,
          payload: {
            userName: profileData.name,
            userEmail: profileData.email,
            contactNumber: profileData.mobileNo === null ? '' : profileData.mobileNo,
            profilePicture: profileData.profilePicture,
            userId: profileData.id,
            passCode: profileData.passCode,
            accountType: profileData.accountType,
          },
        });
      } else {
        const errorMsg = response?.data?.error?.join(', ') || response?.data?.message;
        showAlert(2, errorMsg);
        dispatch(updateGlobalLoader(false));
      }
    })
    .catch((error) => {
      const { response } = error;
      showAlert(2, response?.data?.message);
      dispatch(updateGlobalLoader(false));
    });
};
