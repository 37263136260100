const amexBanner = './assests/images/amexBoy.svg';
const amexLogo = './assests/images/American-Express-Color 1.svg';
const emailIcon = './assests/images/email_Icon.svg';
const passwordIcon = './assests/images/password_icon.svg';
const arrow_left = './assests/images/arrow_left.svg';
const light_amex_logo = './assests/images/American-Express-Color 2.svg';
const green_circle = './assests/images/greenCircle.svg';
const right = './assests/images/right.svg';
const flag_icon = './assests/images/Flag_of_Saudi_Arabia.svg';
const EDIT_ICON = './assests/images/editIcon.svg';
const DELETE_ICON = './assests/images/delete.svg';
const BLOCK_ICON = './assests/images/ic_block.svg';
const PLUS_ICON = './assests/images/ic_add.svg';
const CLEAR_ICON = './assests/images/ic_Clear.svg';

/* ------ new image variable start ------ */

const MAIN_MENU_LOGO = './assests/images/mainMenuLogo.svg';
const BELL_LOGO = './assests/images/bellLogo.svg';
const MENU_HOME = './assests/images/menuHome.svg';
const MENU_USER = './assests/images/menuUser.svg';
const MENU_AUDIT = './assests/images/menuAudit.svg';
const MENU_CASE = './assests/images/caseMenu.svg';
const MENU_INBOX = './assests/images/inboxMenu.svg';
const MENU_TRANSACTION = './assests/images/file_list.svg';
const ACTIVE_MANAGER_LOGO = './assests/images/dashActiveManager.svg';
const ACTIVE_OPERATOR_LOGO = './assests/images/dashActiveOperator.svg';
const BLOACK_MANAGER_LOGO = './assests/images/dashBlckMan.svg';
const BLOCK_OPERATOR_LOGO = './assests/images/dashBlckOperator.svg';
const ACTIVE_ADMIN_LOGO = './assests/images/dashAdmin.svg';
const BLOCK_ADMIN_LOGO = './assests/images/dashBlockAdmin.svg';
const NOTIFICATION_WHITE = './assests/images/notificationWhite.svg';
const DOWNLOAD_ICON = './assests/images/download-icon.png';
const SEARCH_ICON = './assests/images/search.png';
const NO_IMAGE = './assests/images/No_image_available.png';
const MAIN_MENU_TP_BANK = './assests/images/MainMenutpbank.svg';

const SAUDI_BANK = './assests/images/SaudiNational.png';
const AL_RAJHI_BANK = './assests/images/AlRajhi.png';
const RIYAD_BANK = './assests/images/Riyad.png';
const ARAB_NATIONAL_BANK = './assests/images/ArabNational.png';
const ALBILDAL_BANK = './assests/images/Albilal.png';

const ACTIVE_ADMIN = './assests/images/activeAdmin.png';
const ACTIVE_MANAGER = './assests/images/activeManager.png';
const ACTIVE_OPERATOR = './assests/images/actiiveOperator.png';

const BLOCKED_ADMIN = './assests/images/blockedManager.png';
const BLOCKED_MANAGER = './assests/images/blockedAdmin.png';
const BLOCKED_OPERATOR = './assests/images/blockedOperator.png';

const CASES_CLOSED = './assests/images/casesClosed.png';
const CASES_IN_FINAL_CHARGE_BACK = './assests/images/casesInFinalChargeBack.png';
const CASES_IN_FIRST_CHARGE_BACK = './assests/images/casesInFirstChargeBack.png';
const CASES_IN_RETREIVAL_REQUEST = './assests/images/casesInRetreivalRequest.png';
const CASES_REGISTERED_IN_A_DAY = './assests/images/casesRegisteredInADay.png';
const REQUEST_PENDING_BY_ADMIN = './assests/images/requestPendingByAdmin.png';
const APPROVE_USER = './assests/images/approve.svg';
const REJECT_USER = './assests/images/reject.svg';
const DOWNLOAD_PDF = './assests/images/downloadPdf.svg';
const GREY_PLUS = './assests/images/greyPlus.svg';

/* ------ new image variable end ------ */
const LocalImages = {
  amexBanner,
  amexLogo,
  emailIcon,
  passwordIcon,
  arrow_left,
  light_amex_logo,
  green_circle,
  right,
  EDIT_ICON,
  DELETE_ICON,
  BLOCK_ICON,
  PLUS_ICON,
  CLEAR_ICON,
  /* ------ new image variable start ------ */
  MENU_CASE,
  MENU_INBOX,
  MAIN_MENU_LOGO,
  BELL_LOGO,
  MENU_AUDIT,
  MENU_HOME,
  MENU_USER,
  MENU_TRANSACTION,
  ACTIVE_MANAGER_LOGO,
  ACTIVE_OPERATOR_LOGO,
  BLOACK_MANAGER_LOGO,
  BLOCK_OPERATOR_LOGO,
  ACTIVE_ADMIN_LOGO,
  BLOCK_ADMIN_LOGO,
  flag_icon,
  NOTIFICATION_WHITE,
  DOWNLOAD_ICON,
  SEARCH_ICON,
  NO_IMAGE,
  SAUDI_BANK,
  AL_RAJHI_BANK,
  RIYAD_BANK,
  ARAB_NATIONAL_BANK,
  ALBILDAL_BANK,
  ACTIVE_ADMIN,
  ACTIVE_MANAGER,
  ACTIVE_OPERATOR,
  BLOCKED_ADMIN,
  BLOCKED_MANAGER,
  BLOCKED_OPERATOR,
  CASES_CLOSED,
  CASES_IN_FINAL_CHARGE_BACK,
  CASES_IN_FIRST_CHARGE_BACK,
  CASES_IN_RETREIVAL_REQUEST,
  CASES_REGISTERED_IN_A_DAY,
  REQUEST_PENDING_BY_ADMIN,
  MAIN_MENU_TP_BANK,
  APPROVE_USER,
  REJECT_USER,
  DOWNLOAD_PDF,
  GREY_PLUS,
  /* ------ new image variable end ------ */
};

export default LocalImages;
