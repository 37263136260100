import { Dayjs } from 'dayjs';
import ACTION_NAMES from '../../constants/actionNames';

export class CaseInboxState {
  type: number = 1;
  listingType: number = 2;

  listType: number = 1;
  caseListingData = [];
  caseTransactionListing = [];
  caseDetails = {};
  caseListCount: number = 0;
  caseListPage: number = 1;
  caseListLimit: number = 10;
  caseListSearch: string = '';
  caseListPageCount: number = 1;

  startDate: Dayjs | null = null;
  endDate: Dayjs | null = null;
  ApiEndDate: Dayjs | null = null;
  filterStatus = 0;
  isFilter: boolean = false;
  currentSortKey = 'createdAt';
  isCurrentSortAscending = true;
  messageType = 0;

  // Case Details
  case_id: string = '';
  case_caseId: string = '';
  case_startingCardNumber: string = '';
  case_endingCardNumber: string = '';
  case_SENumber: string = '';
  case_SEName: string = '';
  case_merchantCategoryCode: string = '';
  case_ARNNumber: string = '';
  case_terminalNumber: string = '';
  case_transactionDate: string = '';
  case_transactionTime: string = '';
  case_transactionAmount: string = '';
  case_transactionCurrency: string = '';
  case_presentmentAmount: string = '';
  case_networkPresentmentDate = null;
  case_approvalCode: string = '';
  case_transactionId: string = '';
  case_totalDocuments = null;
  case_transactionType = null;
  case_caseType = null;
  case_messageType: string = '';
  case_createdAt: string = '';
  case_actionDate: string = '';
  case_sent = null;
  case_referenceNumber: string = '';
  case_retailerId: string = '';
  filePath: any = [];
  retrievalFiles: any = [];
  fulfilmentFiles: any = [];
  secondPresentmentFiles: any = [];
  actionStep: number = 0;
  fulfillmentReasonCode: any = [];
  secondPresentmentReasonCode: any = [];
  itemizedDocMsg: any = [];
  isActionButton: boolean = true;
}

export const CaseInboxReducer = (
  state: CaseInboxState = new CaseInboxState(),
  action: any = {}
) => {
  if (action.type === ACTION_NAMES.CASE_INBOX) {
    return { ...state, ...action.payload };
  }
  return state;
};
