import { Button, ButtonProps } from '@mui/material';
import { COLORS } from '../../../constants/colors';

export type ContainedButtonProps = {
  title: string;
  noShadow?: boolean;
  externalStyles?: object;
  isShow?: boolean;
};

const ContainedButton = ({
  title,
  noShadow,
  externalStyles,
  isShow = true,
  ...otherProps
}: ContainedButtonProps & ButtonProps) => {
  const styles = {
    btn: {
      textTransform: 'capitalize',
      cursor: 'pointer',
      height: '4.167vw',
      borderRadius: '0.278vw',
      boxShadow: `0 0.069vw 1.181vw ${COLORS.shadow} !important`,
      '& MuiButton-root .Mui-disabled': {
        boxShadow: `0 0.069vw 1.181vw ${COLORS.shadow} !important`,
      },
    },
    btn1: {
      boxShadow: `none !important`,
      '& MuiButton-root .Mui-disabled': {
        boxShadow: `none !important`,
      },
    },
  };

  return (
    <Button
      disableElevation
      variant="contained"
      sx={{
        ...(isShow ? {} : { display: 'none' }),
        ...styles.btn,
        ...externalStyles,
        ...(noShadow ? styles.btn1 : ''),
      }}
      {...otherProps}
    >
      {title}
    </Button>
  );
};

export default ContainedButton;
