const ACTION_NAMES = {
  SIGN_IN: 'SIGN_IN',
  LOADING: 'LOADING',
  IS_LOADING: 'IS_LOADING',
  FORGOT_PASSSWORD: 'FORGOT_PASSSWORD',
  LOCAL_DATA_FETCH: 'LOCAL_DATA_FETCH',
  USER_MANAGE: 'USER_MANAGE',
  USER_PROFILE: 'USER_PROFILE',
  USER_MANAGE_TP_BANK: 'USER_MANAGE_TP_BANK',
  TP_BANK_DATA: 'TP_BANK_DATA',
  CASE_INBOX: 'CASE_INBOX',
  AUDIT_LOG: 'AUDIT_LOG',
  MANAGE_NOTIFICATIONS: 'MANAGE_NOTIFICATIONS',
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
  DASHBOARD: 'DASHBOARD',
  TRANSACTION_DETAILS: 'TRANSACTION_DETAILS',
};

export default ACTION_NAMES;
